<template>
    
    <div class="componentWrapper">
        <div class="titleBlock" :class="streamId ? 'right' : ''">
            <h1 v-if="showTitle">{{ $t("dayprogram.title") }}</h1>
            <div class="buttons">
                
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                    <v-btn v-if="streamId" @click="modal.defaultProgram.display = true" color="blue-grey lighten-5" v-on="on">
                        <v-icon>mdi-history</v-icon>
                    </v-btn>
                    </template>
                    <span>{{$t('dayprogram.buttons.defaultProgram')}}</span>
                </v-tooltip>

                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                    <v-btn @click="openEqualizeModal()" color="blue-grey lighten-5" v-on="on"> 
                        <v-icon>mdi-content-copy</v-icon>
                    </v-btn>
                    </template>
                    <span>{{$t('dayprogram.buttons.equalize')}}</span>
                </v-tooltip>
                
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                    <v-btn @click="undo" color="blue-grey lighten-5" :disabled="!undoReady" :title=" $t('dayprogram.buttons.undo') " v-on="on">
                        <v-icon>mdi-undo</v-icon>
                    </v-btn>
                    </template>
                    <span>{{$t('dayprogram.buttons.undo')}}</span>
                </v-tooltip>

                <v-btn v-if="showSaveButton" @click="saveProgram" color="primary" :disabled="!saveReady">
                    {{ $t(`dayprogram.buttons.save`) }}
                </v-btn>
            </div>
        </div>

        <div class="dayLabels" :style="dayLabelsStyle">
            <div class="label" v-for="(dayItems, day) in program" :key="day" :class="(selectedDay == day) ? 'showOnMobile' : ''"> 
                {{ capitalize($t(`days.${day}`)) }}
            </div>
        </div>

        <div v-if="programReady" class="programContainer">
            <div class="programNavigation" @click="(selectedDay > 1) ? (selectedDay--) : null" :class="(selectedDay > 1) ? 'active' : ''">
                <v-icon>mdi-chevron-left</v-icon>
            </div>
            <div class="program" @scroll="onScrollProgram" ref="program">
                <div class="day" v-for="(dayItems, day) in program" :key="day" :class="(selectedDay == day) ? 'showOnMobile' : ''">
                    <draggable class="items" :list="dayItems" group="same" :move="handleMoveItem" @end="handleDragEndItem" :data-day="day" :disabled="isMobile">
                        <div class="item" v-for="item in dayItems" :key="item.id" :class="item.class" @click="editItem(item.id)" :style="`background-color:${item.colorBackground};`">
                            <div class="left">
                                <div class="colorLabel" :style="`background-color:${item.colorLabel};`"></div>
                                <div class="content">
                                    <div class="time">
                                        {{item.start}} - {{item.end}}
                                    </div>
                                    <div class="text">
                                        {{item.content}}
                                        <v-icon v-if="['image', 'album'].includes(item.category)">mdi-image-outline</v-icon>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </draggable>
                    <div class="addItem" @click="addItem(day)"><v-icon>mdi-plus</v-icon> toevoegen</div>
                </div>
            </div>
            <div class="programNavigation" @click="(selectedDay < 7) ? (selectedDay++) : null" :class="(selectedDay < 7) ? 'active' : ''" :disabled="selectedDay == 7">
                <v-icon>mdi-chevron-right</v-icon>
            </div>
        </div>

        <v-dialog v-model="modal.addOrEdit.display" content-class="modalAddOrEdit" width="500">
            <v-card>
                <v-card-title>
                    <v-autocomplete
                        clearable
                        :label="$t('dayprogram.modal.addOrEdit.content')"
                        :items="modal.addOrEdit.contentOptions"
                        item-text="name"
                        :item-value="item => item.group && item.group.value ? `${item.group.value}__${item.value}`: ''"                        
                        v-model="modal.addOrEdit.item.selection"
                        prepend-inner-icon="mdi-magnify"
                        class="searchContent"
                        height="50"
                        hide-details
                    >
                    
                        <template v-slot:item="data">
                            <template v-if="!isObject(data.item)">
                                <v-list-item-content>
                                    {{ data.item }}
                                </v-list-item-content>
                            </template>
                            <template v-else>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        {{ data.item.name }}
                                    </v-list-item-title>
                                    <v-list-item-subtitle>
                                        {{ data.item.group.text }}
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </template>
                        </template>

                        <template v-slot:no-data>
                            <v-list-item>
                                <v-list-item-content>
                                    {{ $t('dayprogram.modal.addOrEdit.noContent') }}
                                </v-list-item-content>
                            </v-list-item>
                        </template>
                    
                    </v-autocomplete>
                </v-card-title>

                <div class="modalBody">

                    <div class="d-flex justify-space-between pa-3 mb-5 grey lighten-4">
                        <span>Filter:</span>
                        <v-checkbox
                            v-for="group in modal.addOrEdit.blockGroups"
                            :key="group.value"
                            v-model="modal.addOrEdit.activeGroups"
                            :label="group.text"
                            :value="group.value"
                            hide-details
                            class="mt-0 pt-0"
                        ></v-checkbox>
                    </div>

                    <div class="timeRow pt-5">
                        <span>Tijd:</span>
                        <Time v-model="modal.addOrEdit.item.time.from" :label="$t('dayprogram.modal.addOrEdit.time.from')" :bigFont="true" :noMargins="true"/>
                        <span>tot</span>
                        <Time v-model="modal.addOrEdit.item.time.to" :label="$t('dayprogram.modal.addOrEdit.time.to')" :bigFont="true" :noMargins="true"/>
                    </div>

                    <div v-if="modal.addOrEdit.error" class="alert alert-danger">{{modal.addOrEdit.error}}</div>

                </div>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="modal.addOrEdit.display = false">
                        {{ $t("dayprogram.modal.addOrEdit.cancel") }}
                    </v-btn>
                    <v-btn @click="saveItem" color="primary">
                        {{ $t(`dayprogram.modal.addOrEdit.${modal.addOrEdit.mode}.save`) }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="modal.equalize.display" width="500">
            <v-card>
                <v-card-title>
                    {{ $t(`dayprogram.modal.equalize.title`) }}
                </v-card-title>
                
                <v-card-text>
                {{ $t("dayprogram.modal.equalize.description") }} 
                </v-card-text>

                <div class="modalBody">

                    <v-autocomplete
                        clearable
                        :label="$t('dayprogram.modal.equalize.sourceDay')"
                        :items="modal.equalize.days"
                        item-text="day"
                        item-value="dayNumber"
                        v-model="modal.equalize.sourceDay"
                    ></v-autocomplete>

                    <v-autocomplete
                        clearable
                        multiple
                        :label="$t('dayprogram.modal.equalize.targetDays')"
                        :items="modal.equalize.days"
                        item-text="day"
                        item-value="dayNumber"
                        v-model="modal.equalize.targetDays"
                    ></v-autocomplete>

                    <div v-if="modal.equalize.error" class="alert alert-danger">{{modal.equalize.error}}</div>

                </div>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="modal.equalize.display = false">
                        {{ $t("dayprogram.modal.equalize.cancel") }}
                    </v-btn>
                    <v-btn @click="equalize" color="primary">
                        {{ $t(`dayprogram.modal.equalize.save`) }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="modal.saveProgram.display" width="500">
            <v-card>
                <v-card-title>
                    {{ $t(`dayprogram.modal.saveProgram.title`) }}
                </v-card-title>

                <div class="modalBody">
                    <div class="alert alert-danger">{{modal.saveProgram.error}}</div>
                </div>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="modal.saveProgram.display = false">
                        {{ $t("dayprogram.modal.saveProgram.cancel") }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="modal.defaultProgram.display" width="500">
            <v-card>
                <v-card-title>
                    {{ $t(`dayprogram.modal.defaultProgram.title`) }}
                </v-card-title>

                <v-card-text>
                    {{ $t("dayprogram.modal.defaultProgram.description") }} 
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="modal.defaultProgram.display = false">
                        {{ $t("dayprogram.modal.defaultProgram.cancel") }}
                    </v-btn>
                    <v-btn @click="loadDefaultProgram" color="primary">
                        {{ $t(`dayprogram.modal.defaultProgram.load`) }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </div>

</template>


<script>
import axios from "axios";
import { mapGetters } from "vuex";
import { DateTime } from "luxon";
import draggable from 'vuedraggable'
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/nl';
import { ILLI_API_SERVER_URL, DAYPROGRAM_IMAGES_URL } from "../config";
import Time from '@/components/Time.vue';

export default {
    components: {
        draggable,
        Time
    },
    computed: {
        ...mapGetters({
            authenticated: "auth/authenticated",
            user: "auth/user",
        }),
    },
    props: {
        streamId: {
            type: Number,
            default: null,
        },
        showTitle: {
            type: Boolean,
            default: false,
        },
        showSaveButton: {
            type: Boolean,
            default: true,
        },
    },
    data: () => ({
        isMobile: null,
        selectedDay: 1, // used on mobile

        dayLabelsStyle: {
            'margin-left': '0px',
            'padding-right': '0px'
        },

        programReady: false,
        saveReady: false,
        undoReady: false,
        
        history: [],

        defaultProgram: [],
        program: [],
        tags: [],

        moveItem: {},

        modal: {
            addOrEdit: {
                display: false,
                mode: 'add',

                blockGroups: [
                    { text: 'Videoblok', value: 'video' },
                    { text: 'Fotoalbum', value: 'album' },
                    { text: 'Fotoblok', value: 'image' },
                ],
                activeGroups: [],
                contentOptions: [],

                images: [],
                albums: [],

                item: {
                    id: null,
                    tagId: null,
                    photoFolder: null,
                    albumId: null,
                    content: null,
                    time: {
                        from: null,
                        to: null,
                    },
                    day: null,
                    category: null,
                    selection: null,
                },
                error: null
            },
            equalize: {
                display: false,
                sourceDay: 1,
                targetDays: [],
                days: [],
                error: null
            },
            saveProgram: {
                display: false,
                error: null,
            },
            defaultProgram: {
                display: false
            }
        }
    }),
    watch: {
        'modal.addOrEdit.activeGroups': {
            handler() {
                this.createContentSelect();
            }
        }
    },
    created() {

        Promise.all([
            this.getImageFolders(),
            this.getPhotoAlbums(),
            this.getTags()
        ])
        .then(() => {

            this.createContentSelect();
            this.modal.addOrEdit.activeGroups = this.modal.addOrEdit.blockGroups.map(e => e.value);

            // Get program
            let getProgramPromises = [ this.getDefaultDayProgram() ];
            if (this.streamId) getProgramPromises.push(this.getPersonalDayProgram());

            Promise.all(getProgramPromises)
            .then(() => {
            
                this.modal.equalize.days = [1,2,3,4,5,6,7].map((nr) => {
                    return {day: this.$t(`days.${nr}`), dayNumber: nr}
                });

                this.addColorLabelsToProgram();
                this.detectViewport();

                window.addEventListener('resize', this.handleResize);

                this.programReady = true;

                setTimeout(() => {
                    this.checkForScrollbarAndAlterDayLabels();
                }, 500);

            });

        })

    },
    methods: {
        programMapper(program){
            return program.map((e) => {
                const category = this.getCategory(e);
                const content = this.getContent(e);

                return {
                    id: e.id,
                    day: e.day,
                    start: e.start.substring(0,5), 
                    end: e.end.substring(0,5), 
                    content: content, 
                    tagId: e.category, 
                    photoFolder: e.photo_folder || null,
                    albumId: e.album_id || null,
                    empty: false, 
                    class: undefined, 
                    category: category
                }
            });
        },
        getDefaultDayProgram(){
            return new Promise((resolve) => {
                axios
                .get(`${ILLI_API_SERVER_URL}/dayprogram/v2`)
                .then((results) => {
                    this.defaultProgram = this.orderByDay(
                        this.programMapper(results.data.program)
                    );
                    if (!this.streamId) this.program = this.defaultProgram;
                    resolve()
                });
            });
        },
        getPersonalDayProgram(){
            return new Promise((resolve) => {
                axios
                .get(`${ILLI_API_SERVER_URL}/dayprogram/v2/${this.streamId}`)
                .then((results) => {
                    this.program = this.orderByDay(
                        this.programMapper(results.data.program)
                    );
                    console.warn(
                        JSON.parse(JSON.stringify(this.program))
                    );
                    resolve()
                });
            });
        },
        getTags(){
            return new Promise((resolve) => {
                axios
                .get(`${ILLI_API_SERVER_URL}/tags`)
                .then((results) => {
                    this.tags = results.data.tags;
                    resolve();
                });
            });
        },
        getPhotoAlbums(){
            if (!this.streamId) return Promise.resolve([]);
            return new Promise((resolve) => {
                axios
                .get(`${ILLI_API_SERVER_URL}/stream/${this.streamId}/photoAlbums/`)
                .then((result) => {
                    this.modal.addOrEdit.albums = result.data
                        .map(e => ({id: e.id, name: e.name}))
                        .sort((a,b) => a.name.localeCompare(b.name));

                    resolve();
                });
            });
        },
        getImageFolders(){
            return new Promise((resolve, reject) => {
                fetch(DAYPROGRAM_IMAGES_URL, { method: "GET" })
                .then((response) => {
                    response.json().then((images) => {
                        this.modal.addOrEdit.images = images;
                        resolve();
                    })
                })
                .catch((err) => {
                    console.error({err});
                    reject(err)
                });
            });
        },
        getTagById(id){
            const results = this.tags.filter(t => t.id == id);
            if (results.length == 0) console.error(`getTagById(${id}): tag niet gevonden`);
            return (results.length > 0) ? results[0].tag : null;
        },
        getCategory(item){
            return (item.photo_folder || item.photoFolder)
                ? 'image'
                : (item.album_id || item.albumId)
                    ? 'album'
                    : 'video';
        },
        getContentId(item){
            const category = this.getCategory(item);
            return (category == 'image')
                ? item.photo_folder ?? item.photoFolder
                : (category == 'album')
                    ? item.album_id ?? item.albumId
                    : item.tagId;
        },
        getContent(item){
            if (item.selection){
                const [group, value] = item.selection.split('__');
                return (group == 'video')
                    ? this.tags.find(t => t.id == value).tag
                    : (group == 'album')
                        ? this.modal.addOrEdit.albums.find(a => a.id == value).name
                        : value;
            } else {
                const category = this.getCategory(item);
                const tagId = item.tagId ?? item.category;
                const albumId = item.album_id ?? item.albumId;
                return (category == 'image')
                    ? item.photo_folder ?? item.photoFolder
                    : (category == 'album')
                        ? this.modal.addOrEdit.albums.find(a => a.id == albumId).name
                        : this.tags.find(t => t.id == tagId).tag;
            }
        },
        createContentSelect(){
            
            const videoGroup  = this.modal.addOrEdit.blockGroups.find(e => e.value == 'video');
            const albumGroup  = this.modal.addOrEdit.blockGroups.find(e => e.value == 'album');
            const imageGroup  = this.modal.addOrEdit.blockGroups.find(e => e.value == 'image');

            const recommendedTags = [
                { header: 'Aanbevolen videoblokken', group: videoGroup },
                ...this.tags.filter(e => e.categoryId == 2).map(e => ({name: e.tag, group: videoGroup, value: e.id})),
                { divider: true, group: videoGroup },
            ]

            const photoAlbums = (this.modal.addOrEdit.albums.length) 
                ? [
                    { header: 'Fotoalbums', group: albumGroup },
                    ...this.modal.addOrEdit.albums.map(e => ({name: e.name, group: albumGroup, value: e.id})),
                    { divider: true, group: albumGroup},
                ] : [];

            const photoFolders = [
                { header: 'Fotoblokken', group: imageGroup },
                ...this.modal.addOrEdit.images.map(e => ({name: e, group: imageGroup, value: e})),
                { divider: true, group: imageGroup},
            ];

            const tags = [
                { header: 'Alle videoblokken', group: videoGroup },
                ...this.tags.map(e => ({name: e.tag, group: videoGroup, value: e.id})),
            ];

            this.modal.addOrEdit.contentOptions = [
                ...recommendedTags,
                ...photoAlbums,
                ...photoFolders,
                ...tags
            ].filter(e => this.modal.addOrEdit.activeGroups.includes(e?.group?.value));

            if (!this.modal.addOrEdit.contentOptions.length) this.modal.addOrEdit.contentOptions = [this.$t('dayprogram.modal.addOrEdit.noContentCheckFilter')];

            console.warn(
                'contentOptions',
                JSON.parse(JSON.stringify(this.modal.addOrEdit.contentOptions))
            );
        
        },
        updatePhotoAlbums(albums){
            this.modal.addOrEdit.albums = albums.map(a => ({id: a.id, name: a.name}));
        },
        orderByDay(program){
            return program .reduce((all,crr) => {                                
                if (all[crr.day]) all[crr.day].push(crr)
                else all[crr.day] = [crr]
                return all
            }, {});
        },
        detectViewport() {
            this.isMobile = (window.innerWidth <= 800);
        },
        handleResize() {
            this.detectViewport();
            this.checkForScrollbarAndAlterDayLabels();
        },
        delay(ms) { // TODO get from util
            return new Promise(resolve => setTimeout(resolve, ms));
        },
        isObject(item) {
            return (item && typeof item === 'object' && !Array.isArray(item));
        },
        handleDragEndItem() {

            document
                .querySelectorAll('.item')
                .forEach((el) => (el.classList.remove('highlight')));

            this.switchItems({day: this.moveItem.fromDay, index: this.moveItem.fromIndex}, {day: this.moveItem.toDay, index: this.moveItem.toIndex})

        },
        handleMoveItem(event) {

            document
                .querySelectorAll('.item')
                .forEach((el) => (el.classList.remove('highlight')));

            this.moveItem.fromDay = event.from.dataset.day;
            this.moveItem.toDay = event.to.dataset.day;

            this.moveItem.fromIndex = event.draggedContext.index;
            this.moveItem.toIndex = event.relatedContext.index;

            event.from.children[this.moveItem.fromIndex].classList.add('highlight');
            event.to.children[this.moveItem.toIndex].classList.add('highlight');

            return false; // disable default behaviour
        },
        replaceObjectValues(targetObj, valuesObj){
            for (const key in valuesObj) {
                // console.log('set', targetObj, key, valuesObj[key]);
                this.$set(targetObj, key, valuesObj[key]);
            }
        },
        switchItems(from, to){
            console.log('switch', {from, to});

            this.saveStateInHistory();
                    
            const elemFrom = {...this.program[from.day][from.index]};
            const elemTo = {...this.program[to.day][to.index]};
            const tempFrom = {...elemFrom};
            
            console.log({...elemFrom}.empty);
            console.log({...elemTo}.empty);

            elemFrom.start = elemTo.start;
            elemFrom.end = elemTo.end;
            elemFrom.day = elemTo.day;

            elemTo.start = tempFrom.start;
            elemTo.end = tempFrom.end;
            elemTo.day = tempFrom.day;

            console.log({...elemFrom}.empty);
            console.log({...elemTo}.empty);

            this.replaceObjectValues(this.program[from.day][from.index], elemTo);
            this.replaceObjectValues(this.program[to.day][to.index], elemFrom);

            this.stateChange();
        },
        openAddEditModal(){
            this.modal.addOrEdit.error = null;
            this.modal.addOrEdit.display = true;
        },
        addItem(day){
            this.modal.addOrEdit.mode = 'add';

            this.modal.addOrEdit.item.id = null;
            this.modal.addOrEdit.item.tagId = null;
            this.modal.addOrEdit.item.photoFolder = null;
            this.modal.addOrEdit.item.albumId = null;
            this.modal.addOrEdit.item.time.from = '00:00';
            this.modal.addOrEdit.item.time.to = '23:59';
            this.modal.addOrEdit.item.day = day;
            this.modal.addOrEdit.item.category = 'video';

            this.openAddEditModal();
        },
        editItem(id){
            this.modal.addOrEdit.mode = 'edit';
            
            const selectedItem = Object.values(this.program).flat().filter(e => e.id == id)[0];
            
            const contentId = this.getContentId(selectedItem);

            this.modal.addOrEdit.item.id = id;
            this.modal.addOrEdit.item.tagId = selectedItem.tagId;
            this.modal.addOrEdit.item.photoFolder = selectedItem.photoFolder;
            this.modal.addOrEdit.item.albumId = selectedItem.albumId;
            this.modal.addOrEdit.item.time.from = selectedItem.start;
            this.modal.addOrEdit.item.time.to = selectedItem.end;
            this.modal.addOrEdit.item.day = selectedItem.day;
            this.modal.addOrEdit.item.category = selectedItem.category;
            this.modal.addOrEdit.item.selection = `${selectedItem.category}__${contentId}`;

            this.modal.addOrEdit.activeGroups = [...new Set([...this.modal.addOrEdit.activeGroups, selectedItem.category])];

            this.openAddEditModal();
        },
        saveItem(){
            if (!this.validateSaveItem().valid) return;
            
            this.saveStateInHistory();

            let savedItem = {...this.modal.addOrEdit.item};

            const [group, value] = savedItem.selection.split('__');
            savedItem.category = group;
            savedItem.tagId = (savedItem.category == 'video') ? value : null;
            savedItem.photoFolder = (savedItem.category == 'image') ? value : null;
            savedItem.albumId = (savedItem.category == 'album') ? value : null;
            savedItem.content = this.getContent(savedItem);
            
            if (this.modal.addOrEdit.mode == 'add'){

                // Find index to insert item
                const savedItemStartTime = this.timeToInt(savedItem.time.from);
                const savedItemEndTime = this.timeToInt(savedItem.time.to);

                const dayInReverse = [...this.program[savedItem.day]].reverse();
                const indexReverse = dayInReverse.findIndex((item) => {
                    const itemStartTime = this.timeToInt(item.start);
                    const result = savedItemEndTime >= itemStartTime;
                    return (result);
                });

                const savedItemInsideOtherItem = dayInReverse.filter((item) => {
                    const itemStartTime = this.timeToInt(item.start);
                    const itemEndTime = this.timeToInt(item.end);
                    return (savedItemStartTime > itemStartTime && savedItemEndTime <= itemEndTime)
                }).length > 0;

                const standardIndex = (dayInReverse.length - 1 - indexReverse);
                const index = (savedItemInsideOtherItem) ? standardIndex + 1 : standardIndex;

                // Insert item
                const id = this.insertBlock(
                    savedItem.day,
                    index,
                    savedItem.time.from,
                    savedItem.time.to,
                    savedItem.category,
                    savedItem.tagId,
                    savedItem.content,
                    savedItem.photoFolder,
                    savedItem.albumId,
                );

                this.modal.addOrEdit.item.id = id;
                this.alterDay(this.modal.addOrEdit.item.day);

            } else if (this.modal.addOrEdit.mode == 'edit') {

                const previousValues = {...Object.values(this.program).flat().filter(e => e.id == this.modal.addOrEdit.item.id)[0]};

                // Edit item
                this.program[savedItem.day] = this.program[savedItem.day].map((item) => {
                    return (item.id != savedItem.id) ? item :
                        {
                            ...item, 
                            tagId: savedItem.tagId,
                            content: savedItem.content,
                            photoFolder: savedItem.photoFolder,
                            albumId: savedItem.albumId,
                            start: savedItem.time.from,
                            end: savedItem.time.to,
                            category: savedItem.category,
                            class: undefined,
                            empty: false
                        };
                });

                this.alterDay(savedItem.day, previousValues);

            }

            this.addColorLabelsToProgram();
            this.modal.addOrEdit.display = false;
            this.stateChange();
        },
        validateSaveItem(){
            const required = [
                this.modal.addOrEdit.item.selection,
                this.modal.addOrEdit.item.time.from,
                this.modal.addOrEdit.item.time.to
            ];

            const requiredValid = (required.filter(e => e).length == required.length);
            const timesValid = (this.timeToInt(this.modal.addOrEdit.item.time.from) <= this.timeToInt(this.modal.addOrEdit.item.time.to));
            const valid = (requiredValid && timesValid);

            const message = (valid) ? null : (!requiredValid) 
                ? 'Vul alle velden in'
                : 'Tijden niet correct';

            this.modal.addOrEdit.error = message;
            return {valid, message};
        },
        validateEqualize(){
            const valid = 
                (this.modal.equalize.sourceDay != undefined) &&
                (this.modal.equalize.targetDays.length > 0)

            const message = (!valid) ? 'Vul alle velden in' : null;

            this.modal.equalize.error = message;
            return {valid, message};
        },
        validateSaveProgram(showError = true){
            const daysWithEmptyItems = [...new Set(Object.values(this.program).flat().filter(e => e.tagId == null && e.photoFolder == null && e.albumId == null).map(e => e.day))];
            const valid = (daysWithEmptyItems.length == 0);
            const daysStr = daysWithEmptyItems.map(day => this.$t(`days.${day}`)).join(', ');
            const message = (!valid) ? `${this.$t('dayprogram.modal.saveProgram.emptyDays')}: ${daysStr}. ${this.$t('dayprogram.modal.saveProgram.addContent')}` : null;

            if (showError){
                this.modal.saveProgram.display = !valid;
                this.modal.saveProgram.error = message;
            }
            return {valid, message};
        },
        getTemporaryId(){ // Used to (temporarly) identify items
            const maxId = Object.values(this.program).flat().reduce((all, crr) => (crr.id > all ? crr.id : all), 0);
            return maxId + 1;
        },
        insertBlock(day, index, start, end, category, tagId = null, content = null, photoFolder = null, albumId = null, className = undefined, empty = false){
            const item  = {
                id: this.getTemporaryId(),
                day: day,
                start: start,
                end: end,
                category: category,
                tagId: tagId,
                content: content,
                photoFolder: photoFolder,
                albumId: albumId,
                class: className,
                new: true,
                empty: empty,
            };
            console.warn('INSERTED', {day, index}, {...item});
            this.program[day].splice(index, 0, item);
            return item.id;
        },
        insertEmptyBlock(day, index, start, end){
            this.insertBlock(day, index, start, end, 'video', null, 'Leeg', null, null, 'empty', true);
        },
        alterDay(selectedDay, previousValues = null){ // After add or insert, alter other items in day

            const selectedStart = this.timeToInt(this.modal.addOrEdit.item.time.from);
            const selectedEnd = this.timeToInt(this.modal.addOrEdit.item.time.to);

            // Remove completely overlapping items
            this.program[selectedDay] = this.program[selectedDay].filter((item) => {
                const itemStart = this.timeToInt(item.start);
                const itemEnd = this.timeToInt(item.end);

                return (
                    item.id == this.modal.addOrEdit.item.id ||
                    !(itemStart >= selectedStart && itemEnd <= selectedEnd)
                );
            });

            let afterwardsInsertBlock = undefined;

            // Alter partly overlapping items
            this.program[selectedDay] = this.program[selectedDay].map((item, index) => {
                const itemStart = this.timeToInt(item.start);
                const itemEnd = this.timeToInt(item.end);
                const overlap = (itemStart <= selectedEnd && itemEnd >= selectedStart);

                // Alter
                if (item.id != this.modal.addOrEdit.item.id && overlap){ 
                        
                    const alteration = (itemEnd >= selectedEnd && itemStart >= selectedStart)
                        ? {start: this.timePlus(this.intToTime(selectedEnd), 1)}
                        : {end: this.timePlus(this.intToTime(selectedStart), -1)}

                    // When new item is in middle of other item
                    //  Duplicate other item to give the illusion other item is split
                    if (selectedStart > itemStart && selectedEnd < itemEnd){
                        afterwardsInsertBlock = [
                            selectedDay,
                            index + 2,
                            this.timePlus(this.intToTime(selectedEnd), 1),
                            item.end,
                            item.category,
                            item.tagId,
                            this.getTagById(item.tagId),
                            item.photoFolder,
                            item.albumId
                        ];
                    } 

                    return {
                        ...item,
                        ...alteration
                    }
                }

                return item;
            });

            if (afterwardsInsertBlock) this.insertBlock(...afterwardsInsertBlock);

            if (previousValues){ // Add empty items where necessary 
                
                const currentValues = {...Object.values(this.program).flat().filter(e => e.id == this.modal.addOrEdit.item.id)[0]};
                const index = this.program[selectedDay].findIndex(e => e.id == this.modal.addOrEdit.item.id);

                // Insert before
                if (this.timeToInt(previousValues.start) < this.timeToInt(currentValues.start)){ 

                    const start = (index == 0) 
                        ? "00:00" 
                        : this.timePlus(this.program[selectedDay][index-1].end, 1);
                    const end = this.timePlus(currentValues.start, -1);

                    this.insertEmptyBlock(selectedDay, index, start, end);

                }
                // Insert after
                if (this.timeToInt(previousValues.end) > this.timeToInt(currentValues.end)) { 
                    
                    const maxIndex = this.program[selectedDay].length - 1;
                    const start = this.timePlus(currentValues.end, 1);
                    const end = (index == maxIndex)
                        ? "23:59"
                        : this.timePlus(this.program[selectedDay][index+1].start, -1);

                    this.insertEmptyBlock(selectedDay, index + 1, start, end);

                }
            }

            // Reindex array
            this.program[selectedDay].filter(_ => _ || true); 
        },
        capitalize(string){
            return string.charAt(0).toUpperCase() + string.slice(1);
        },
        timeToInt(time){
            return parseInt(time.split(':').join(''));
        },
        intToTime(int){
            const str = int.toString().padStart(4, '0');
            const hour = parseInt(str.slice(0, 2));
            const minute = parseInt(str.slice(2));

            return DateTime.now().set({hour, minute}).toFormat('HH:mm')
        },
        timePlus(time, plusMinutes){
            return DateTime.fromFormat(time, 'HH:mm').plus({minute: plusMinutes}).toFormat('HH:mm');
        },
        getDistinctColors(n) {
            const distinctColors = [];
            const increment = 360 / n;

            for (let i = 0; i < n; i++) {
                const hue = (i * increment) % 360;
                const saturation = 0.7;
                const lightnessLabel = 0.6;
                const lightnessBackground = 0.95;
                const colorLabel = `hsl(${hue}, ${saturation * 100}%, ${lightnessLabel * 100}%)`;
                const colorBackground = `hsl(${hue}, ${saturation * 100}%, ${lightnessBackground * 100}%)`;
                distinctColors.push({colorLabel, colorBackground});
            }

            return distinctColors;
        },
        addColorLabelsToProgram(){ 
            const uniqueContent = [...new Set(Object.values(this.program).flat().map(e => e.content))];
            const distinctColors = this.getDistinctColors(uniqueContent.length);
            const contentColors = uniqueContent.reduce((all, tag, idx) => {
                all[tag] = distinctColors[idx];
                return all;
            }, {})

            this.program = Object.fromEntries(
                Object.entries(this.program).map(([day, items]) => [
                    day,
                    items.map((item) => ({ ...item, colorLabel: contentColors[item.content].colorLabel, colorBackground: contentColors[item.content].colorBackground})),
                ])
            );
        },
        prepareProgramDataForApi(){
            return Object.values(this.program).flat()
                .map(e => ({...e, id: null, category: e.tagId, stream_id: this.streamId, photo_folder: e.photoFolder, album_id: e.albumId, colorBackground: undefined, colorLabel: undefined, class: undefined, content: undefined, empty: undefined, tagId: undefined, photoFolder: undefined, albumId: undefined}))
        },
        saveProgram(){
            if (!this.validateSaveProgram().valid) return;

            // Prepare data
            const program = this.prepareProgramDataForApi();

            axios
            .post(`${ILLI_API_SERVER_URL}/dayprogram/v2`, {
                program: program
            })
            .then((result) => {
                console.log({result});
                this.saveReady = false;
            })
            .catch((err) => {
                console.error({err});
            })
        },
        stateChange(){ // emits changes to parent components
            this.saveReady = true; // enable save button
            const program = this.prepareProgramDataForApi(this.program);
            const validation = this.validateSaveProgram(false);
            this.$emit("change", {program, validation});
            this.checkForScrollbarAndAlterDayLabels();
        },
        saveStateInHistory(){
            const program = JSON.parse(JSON.stringify(this.program));
            this.history.push(program);
            this.undoReady = true;
        },
        undo(){
            if (this.history.length > 0){
                this.program = this.history.pop();
                this.undoReady = (this.history.length > 0);
                this.stateChange();
            }
        },
        openEqualizeModal(){
            this.modal.equalize.error = null;
            this.modal.equalize.display = true;
        },
        equalize(){
            if (!this.validateEqualize().valid) return;
            this.saveStateInHistory();

            const sourceDayItems = [...this.program[this.modal.equalize.sourceDay]];
            const targetDays = this.modal.equalize.targetDays;
  
            this.program = Object.fromEntries(
                Object.entries(this.program).map(([day, dayItems]) => {
                    const items = (targetDays.includes(parseInt(day)))
                        ? sourceDayItems.map(e => e = {...e, day: parseInt(day)})
                        : dayItems;

                    return [
                        day,
                        items
                    ]
                })
            );

            this.modal.equalize.display = false;
            this.stateChange();
        },
        loadDefaultProgram(){
            this.saveStateInHistory();
            this.program = {...this.defaultProgram};
            this.addColorLabelsToProgram();
            this.modal.defaultProgram.display = false;
            this.stateChange();
        },
        onScrollProgram(e){
            const marginLeft = e.target.scrollLeft * -1;
            this.dayLabelsStyle['margin-left'] = `${marginLeft}px`;
        },
        checkForScrollbarAndAlterDayLabels() {
            const program = this.$refs.program;

            if (program && program.scrollHeight > program.clientHeight) {
                const scrollbarWidth = program.offsetWidth - program.clientWidth;
                this.dayLabelsStyle['padding-right'] = `${scrollbarWidth}px`;
            }
        },
    }
}
</script>

<style lang="scss">

    html{
        overflow: auto;
    }

    .componentWrapper{
        display: flex;
        flex-direction: column;
        overflow: hidden;
        height: 100%;
    }

    .titleBlock{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .titleBlock.right{
        justify-content: flex-end;
    }

    .titleBlock .buttons{
        display: flex;
        gap: 10px;
        padding-bottom: 10px;
    }

    .dayLabels{
        display: flex;
        justify-content: space-between;
        gap: 20px;
    }

    .dayLabels .label{
        flex: 1;
        background: #ffffff;
        padding: 10px;
        text-align: center;
        font-weight: 600;
        color: #595959;
        min-width: 150px;
    }

    .programContainer{
        display: flex;
        overflow: auto;
    }

    .programContainer .programNavigation{
        display: none;
        border: 1px solid rgb(212, 212, 212);
        color: rgb(212, 212, 212);
    }

    .programContainer .programNavigation.active{
        border: 1px solid grey;
        color: grey;    
        cursor: pointer;
    }

    .programContainer .programNavigation .v-icon{
        font-size: 40px;
        color: inherit;
    }

    .program{
        display: flex;
        flex: 1;
        justify-content: space-between;
        gap: 20px;
        overflow: auto;
    }

    .program .day{
        flex: 1;
        position: relative;
        min-width: 150px;
    }

    .program .day .items{
        padding-top: 1px;
    }

    .program .day .items .item{
        background: #f1f1f1;
        border: 1px solid #eaeaea;
        margin-top: -1px;
        display: flex;
        justify-content: space-between;
        cursor: pointer;
        position: relative;
    }

    .program .day .items .item:hover:after{
        content: '';
        background: rgba(0, 0, 0, 0.05);
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
    }

    .program .day .items .item.highlight:after{
        content: '';
        background: rgba(0, 0, 0, 0.2);
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
    }

    .program .day .items .item.empty{
        background: #a1a1a1!important;
    }
    
    .program .day .items .item .left{
        display: flex;
    }

    .program .day .items .item .left .colorLabel{
        width: 5px;
        height: 100%;
    }

    .program .day .items .item.empty .left .colorLabel {
        background-color: black!important;
    }

    .program .day .items .item .left .content{
        display: flex;
        flex-direction: column;
        padding: 5px;
    }

    .program .day .items .item .left .content .time {
        font-size: 11px;
    }

    .program .day .items .item .left .content .text{
        font-weight: 500;
    }

    .program .day .items .item .edit{
        display: none;
        align-items: center;
        padding-right: 10px;
        cursor: pointer;
        color: #6f6f6f;
    }

    .program .day .items .item.empty .edit{
        display: flex;
        color: black;
    }

    .program .day .items .item:hover .edit{
        display: flex;
    }

    .program .day .items .item .edit .v-icon{
        color: inherit;
    }

    .program .day .addItem{
        position: sticky;
        bottom: 0;
        left: 0;
        width: 100%;
        background: #ffffff;
        padding: 10px;
        text-align: center;
        font-weight: 500;
        border: 1px solid grey;
        cursor: pointer;
        color: #595959;
    }

    .modalAddOrEdit .searchContent{
        font-size: 30px;
        
        .v-input__prepend-inner{
            align-items: center;
            padding: 0;
            margin: 0;
            height: 100%;
        }
    }

    .modalAddOrEdit .highlight .v-input__slot{
        background: #d4ffd4;
    }

    .modalAddOrEdit .toggleTagOptions{
        margin-bottom: 15px;
        display: inline-block;
    }

    .modalAddOrEdit .timeRow{
        display: flex;
        align-items: center;
        gap: 30px;
        margin-bottom: 15px;

        // .v-input{
        //     width: 100px;
        // }
    }

    footer.v-footer{ 
        display: none;
    }

    .v-subheader{
        font-weight: 600!important;
    }

    @media screen and (max-width: 800px){

        .dayLabels{
            padding-right: 0!important;
        }

        .dayLabels .label{
            display: none;
        }

        
        .dayLabels .label.showOnMobile{
            display: block;
        }
            
        .programContainer{
            overflow: hidden;
        }

        .programContainer .programNavigation{
            display: flex;
        }

        .program .day{
            display: none;
        }

        .program .day .addItem{ 
            margin-left: -1px;
            margin-right: -1px;
        }

        .program .day.showOnMobile{
            display: block;
        }

        .program .day .items .item{
            cursor: pointer;
        }

    }

</style>